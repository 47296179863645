const accessibility = () => {
  // Menu social aria label

  const menuSocials = document.querySelectorAll(".menu__social");
  if (menuSocials.length > 0) {
    menuSocials.forEach((menuSocial) => {
      const links = menuSocial.querySelectorAll("a");
      if (links.length > 0) {
        links.forEach((link) => {
          const iconClassList = link.querySelector("i").classList;
          if (iconClassList.length > 0) {
            iconClassList.forEach((iconClass) => {
              const aria = iconClass.replace("bi", "").replace("bi-", "");
              link.setAttribute("aria-label", aria);
            });
          }
        });
      }
    });
  }

  const inputs = document.querySelectorAll("input");
  if (inputs.length > 0) {
    inputs.forEach((input) => {
      if (input.placeholder) {
        input.setAttribute("aria-label", input.placeholder);
      }else if (input.name) {
        input.setAttribute("aria-label", input.name);
      }
    });
  }
};

export default accessibility;
