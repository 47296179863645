const navbar = () => {
  document.addEventListener("DOMContentLoaded", () => {
    const navbar = document.querySelector(".navbar__section");
    if (!navbar) return;

    const toggler = navbar.querySelector(".navbar__btn__toggler");
    const close = navbar.querySelector(".navbar__btn__close");
    const offcanvas = navbar.querySelector(".navbar__offcanvas");
    const searchBtns = Array.from(document.querySelectorAll(".search-btn"));
    const searchModal = document.getElementById("modalPanelSearch");
    const modalBtns = Array.from(document.querySelectorAll(".modal__btn__btn"));
    const modalPanels = Array.from(document.querySelectorAll(".modal__panel"));

    // Helper Function to Close Modals (Optionally Keep Search Modal Open)
    const closeAllModals = (closeSearch = true) => {
      offcanvas?.classList.remove("open");
      if (closeSearch) {
        searchModal?.classList.remove("open");
      }
      modalPanels.forEach((modalPanel) => modalPanel.classList.remove("open"));
    };

    // Open Offcanvas
    toggler?.addEventListener("click", (e) => {
      e.preventDefault();
      offcanvas?.classList.add("open");
    });

    // Close Offcanvas (Without Closing Search Modal)
    close?.addEventListener("click", (e) => {
      e.preventDefault();
      closeAllModals(false); // Keep search modal open
    });

    // Navbar Scrolled Effect
    window.addEventListener("scroll", () => {
      const header_top = 30;
      navbar.classList.toggle("scrolled", window.scrollY > header_top);
    });

    // Search Modal Toggle (Open/Close)
    if (searchBtns.length > 0 && searchModal) {
      searchBtns.forEach((searchBtn) => {
        searchBtn.addEventListener("click", () => {
          const isOpen = searchModal.classList.contains("open");

          closeAllModals(); // Close all modals first

          if (!isOpen) {
            searchModal.classList.add("open"); // Reopen only if it was closed
          }
        });
      });
    }

    // Other Modal Toggle
    if (modalBtns.length > 0) {
      modalBtns.forEach((modalBtn) => {
        modalBtn.addEventListener("click", () => {
          const targetId = modalBtn.getAttribute("aria-target");
          const targetPanel = document.getElementById(targetId);

          if (!targetPanel) return;

          const isOpen = targetPanel.classList.contains("open");

          closeAllModals(); // Close all modals first

          if (!isOpen) {
            targetPanel.classList.add("open");
          }
        });
      });
    }

    // Close Navbar & Search Modal on Outside Click
    window.addEventListener("click", (e) => {
      if (offcanvas?.classList.contains("open") || searchModal?.classList.contains("open") || modalPanels.some((panel) => panel.classList.contains("open"))) {
        const clickedInside = [offcanvas?.contains(e.target), toggler?.contains(e.target), searchModal?.contains(e.target), ...searchBtns.map((btn) => btn.contains(e.target)), ...modalBtns.map((btn) => btn.contains(e.target)), ...modalPanels.map((panel) => panel.contains(e.target))].some(Boolean);

        if (!clickedInside) {
          closeAllModals();
        }
      }
    });

    const modalBtnList = document.querySelector(".modal__btn__list");
    if (modalBtnList) {
      function checkScroll() {
        if (window.innerWidth < 576 && window.scrollY > 80) {
          modalBtnList.style.transform = "translateX(80%)";
        } else {
          modalBtnList.style.transform = "translateX(0)";
        }
      }

      window.addEventListener("scroll", checkScroll);

      modalBtnList.addEventListener("click", () => {
        modalBtnList.style.transform = "translateX(0)";
      });
    }
  });
};

export default navbar;
