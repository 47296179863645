const archiveEvent = () => {
  // Calendar
  const eventCalendar = document.getElementById("event-calendar");
  if (eventCalendar) {
    let config = {
      mode: "range",
      inline: true,
      dateFormat: "Ymd",
    };

    const currentLang = document.documentElement.lang;
    if (currentLang === "it-IT") {
      config.locale = Italian;
    }

    flatpickr(eventCalendar, config);
  }
};

export default archiveEvent;
