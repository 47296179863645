let markers = []; // Array to store all markers

const initMap = async (response) => {
  const mapElement = document.getElementById("map");
  if (mapElement) {
    const startPos = {
      lat: 45.7255991,
      lng: 9.9006726,
    };

    const { Map, InfoWindow } = await google.maps.importLibrary("maps");
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

    // Create Map
    const mapFrame = new Map(mapElement, {
      center: startPos,
      zoom: 12,
      mapId: "invalcavallinamap",
    });

    // Init InfoWindow
    const infoWindow = new InfoWindow();

    if (typeof response !== "undefined") {
      // Clear existing markers before adding new ones
      clearMarkers();

      if (Array.isArray(response) && response.length > 0) {
        response.forEach(async (item, i) => {
          if (Array.isArray(item.relation) && item.relation.length > 0) {
            // Pin Position
            const position = {
              lat: item.map.lat,
              lng: item.map.lng,
            };

            // Pin Icon
            const parser = new DOMParser();
            const postTypeSetting = getPostTypeSetting(item.relation[0].post_type, "#ff0000", item.relation[0].post_type_label);
            const pinSvgString = `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" stroke="#fff" fill="${postTypeSetting.color}" scale="1" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
  <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
  </svg>`;

            // Marker
            const marker = new AdvancedMarkerElement({
              position: position,
              map: mapFrame,
              title: `${i + 1}. ${item.title}`,
              content: parser.parseFromString(pinSvgString, "image/svg+xml").documentElement,
              gmpClickable: true,
            });

            // Add the marker to the markers array
            markers.push(marker);

            const relations = item.relation;

            marker.addListener("gmp-click", (event) => {
              let infoWindowContent = "";

              if (Array.isArray(relations) && relations.length > 0) {
                // Ensure relations is defined and an array
                relations.forEach((rel) => {
                  // Check if post_type exists
                  const postTypeSetting = getPostTypeSetting(rel.post_type, "#ff0000", rel.post_type_label);
                  const nicePostType = postTypeSetting.postTypeLabel ? postTypeSetting.postTypeLabel.replace(/_/g, " ") : "";

                  // Start constructing the infoWindowContent string
                  infoWindowContent += `<a href="${rel.permalink ? rel.permalink : "#"}"><h5>${rel.title ? rel.title : "No Title"}</h5></a>`;
                  infoWindowContent += `<p>${nicePostType}</p>`;

                  // Check if contacts and its fields exist before accessing
                  if (rel.contacts) {
                    if (rel.contacts.address) {
                      infoWindowContent += `<p class='mb-0'>${rel.contacts.address}</p>`;
                    }
                    if (rel.contacts.tel) {
                      infoWindowContent += `<p class="mb-0">Tel: <a href="tel:${rel.contacts.tel}">${rel.contacts.tel}</a></p>`;
                    }
                    if (rel.contacts.email) {
                      infoWindowContent += `<p>Email: <a href="mailto:${rel.contacts.email}">${rel.contacts.email}</a></p>`;
                    }
                  }

                  // Check if services exist before adding list
                  if (Array.isArray(rel.services) && rel.services.length > 0) {
                    infoWindowContent += "<ul>";
                    rel.services.forEach((serv) => {
                      if (serv.label) {
                        infoWindowContent += `<li class='mb-0'>${serv.label}</li>`;
                      }
                    });
                    infoWindowContent += "</ul>";
                  }
                });
              } else {
                infoWindowContent = "<p>No related posts found.</p>";
              }

              infoWindow.close();
              infoWindow.setContent(infoWindowContent);
              infoWindow.open(marker.map, marker);
            });
          }
        });
      }
    } else {
      // If no results, delete all pins from the map
      clearMarkers();
    }
  }
};

// Function to clear all markers from the map
const clearMarkers = () => {
  for (let i = 0; i < markers.length; i++) {
    markers[i].map = null; // Remove marker from map
  }
  markers = []; // Clear the markers array
};

export default initMap;

/**
 *
 * Get Post Type english labels & colors
 *
 * @param {*} postType
 * @param {*} color
 * @returns
 */
const getPostTypeSetting = (postType, color, postTypeLabel) => {
  const lang = document.documentElement.lang;
  let res = {
    postType: postType,
    color: color,
    postTypeLabel: postTypeLabel,
  };

  switch (postType) {
    case "luogo_di_interesse":
      res = {
        postType: lang === "en-US" ? "place_of_intrest" : postType,
        color: "#F68C74",
        postTypeLabel: lang === "en-US" ? "Place of intrest" : postTypeLabel,
      };
      break;
    case "esperienza":
      res = {
        postType: lang === "en-US" ? "experience" : postType,
        color: "#74CAC7",
        postTypeLabel: lang === "en-US" ? "Experience" : postTypeLabel,
      };
      break;
    case "paese":
      res = {
        postType: lang === "en-US" ? "village" : postType,
        color: "#90CA7E",
        postTypeLabel: lang === "en-US" ? "Village" : postTypeLabel,
      };
      break;
    case "ospitalita":
      res = {
        postType: lang === "en-US" ? "hospitality" : postType,
        color: "#F4CA5F",
        postTypeLabel: lang === "en-US" ? "Hospitality" : postTypeLabel,
      };
      break;
    case "sapore":
      res = {
        postType: lang === "en-US" ? "taste" : postType,
        color: "#CE82A6",
        postTypeLabel: lang === "en-US" ? "Taste" : postTypeLabel,
      };
      break;
  }
  return res;
};
