const frontPage = () => {
  const block = document.querySelector(".front-page__hero__section");

  // Define seasons based on month
  const seasons = ["inverno", "primavera", "estate", "autunno"];
  const currentSeason = getCurrentSeason();

  if (block) {
    const mediaItems = block.querySelectorAll(".front-page__hero__media__item");
    const btns = block.querySelectorAll(".front-page__hero__nav__btn");

    if (mediaItems.length > 0) {
      mediaItems.forEach((mediaItem) => {
        const season = mediaItem.dataset.season;

        if (season === currentSeason) {
          mediaItem.classList.add("show");
          const video = mediaItem.querySelector(".front-page__hero__media__video");
          if (video) video.play();
        } else {
          mediaItem.classList.remove("show");
        }
      });
    }

    if (btns.length > 0) {
      btns.forEach((btn) => {
        const season = btn.dataset.season;

        if (season === currentSeason) {
          btn.classList.add("active");
        } else {
          btn.classList.remove("active");
        }

        btn.addEventListener("click", () => {
          if (season) {
            mediaItems.forEach((mediaItem) => {
              const video = mediaItem.querySelector(".front-page__hero__media__video");

              if (mediaItem.dataset.season === season) {
                mediaItem.classList.add("show");
                if (video) video.play();
              } else {
                mediaItem.classList.remove("show");
                if (video) {
                  video.pause();
                  video.currentTime = 0;
                }
              }
            });

            // Update active button state
            btns.forEach((b) => b.classList.remove("active"));
            btn.classList.add("active");
          }
        });
      });
    }
  }
};

export default frontPage;

/**
 * Get current season
 *
 * @returns
 */

const getCurrentSeason = () => {
  const date = new Date();
  const month = date.getMonth() + 1; // Months are zero-based
  const day = date.getDate();

  if ((month === 3 && day >= 20) || month === 4 || month === 5 || (month === 6 && day < 21)) {
    return "primavera"; // Spring: March 20 - June 20
  }
  if ((month === 6 && day >= 21) || month === 7 || month === 8 || (month === 9 && day < 23)) {
    return "estate"; // Summer: June 21 - September 22
  }
  if ((month === 9 && day >= 23) || month === 10 || month === 11 || (month === 12 && day < 21)) {
    return "autunno"; // Fall: September 23 - December 20
  }
  return "inverno"; // Winter: December 21 - March 19
};
