import { filterResults, getSelectedFilters } from "./archiveFilters";

export const archivePagination = (totalPages, currentPage) => {
  const paginationContainer = document.getElementById("paginationContainer");
  if (!paginationContainer) return;

  clearPagination();

  if (totalPages > 1) {
    const paginationList = document.createElement("ul");
    paginationList.classList.add("archive-pagination__list");

    // Previous Button
    paginationList.appendChild(getPaginationItem("&#8592;", false, currentPage === 1, currentPage - 1));

    // Page Numbers
    for (let i = 1; i <= totalPages; i++) {
      paginationList.appendChild(getPaginationItem(`${i}`, i === currentPage, false, i));
    }

    // Next Button
    paginationList.appendChild(getPaginationItem("&#8594;", false, currentPage === totalPages, currentPage + 1));

    paginationContainer.appendChild(paginationList);

    // Add event listeners for pagination
    paginationLinksListener();
  }
};

/**
 * Clear Pagination
 */

export const clearPagination = () => {
  const paginationContainer = document.getElementById("paginationContainer");
  if (paginationContainer) {
    paginationContainer.innerHTML = "";
  }
};

/**
 * Get Pagination Item
 *
 * @param {*} label
 * @param {*} isActive
 * @param {*} isDisabled
 * @param {*} pageNumber
 * @returns
 */

const getPaginationItem = (label, isActive, isDisabled, pageNumber) => {
  const item = document.createElement("li");
  const linkClass = "archive-pagination__link";
  item.classList.add("archive-pagination__item");
  if (isActive) item.classList.add("active");

  if (isDisabled) {
    item.classList.add("disabled");
    item.innerHTML = `<span class="${linkClass}" aria-label="${label}">${label}</span>`;
  } else {
    item.innerHTML = `<a href="#" class="${linkClass}" aria-label="${label}"${pageNumber ? ` data-page="${pageNumber}"` : ``}>${label}</a>`;
  }

  return item;
};

/**
 * Pagination Links Listener
 */

const paginationLinksListener = () => {
  document.querySelectorAll(".archive-pagination__link").forEach((button) => {
    button.addEventListener("click", (e) => {
      e.preventDefault();
      const page = parseInt(e.target.dataset.page);
      if (!isNaN(page)) {
        filterResults(getSelectedFilters(), page);
      }
    });
  });
};
