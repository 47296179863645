const sectionDestination = () => {
  const block = document.querySelector(".section-destination__slider");

  if (block) {
    const calculateOffset = () => {
      if (window.innerWidth >= 1900) {
        return (window.innerWidth - 1600 * 0.75) / 2 + 36;
      } else if (window.innerWidth >= 1400) {
        return (window.innerWidth - 1320 * 0.75) / 2 + 36;
      } else if (window.innerWidth >= 1200) {
        return (window.innerWidth - 1140 * 0.75) / 2 + 36;
      } else if (window.innerWidth >= 992) {
        return (window.innerWidth - 960 * 0.75) / 2 + 36;
      } else if (window.innerWidth >= 768) {
        return (window.innerWidth - 720) / 2 + 36;
      } else if (window.innerWidth >= 576) {
        return (window.innerWidth - 540) / 2 + 36;
      }
      return 36;
    };

    const swiper = new Swiper(block, {
      slidesPerView: 3,
      spaceBetween: 10,
      slidesOffsetBefore: calculateOffset(),
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        576: {
          slidesPerView: 4,
          spaceBetween: 20,
          slidesOffsetBefore: calculateOffset(),
        },
        768: {
          slidesPerView: 4.5,
          spaceBetween: 20,
          slidesOffsetBefore: calculateOffset(),
        },
        992: {
          slidesPerView: 4.5,
          spaceBetween: 20,
          slidesOffsetBefore: calculateOffset(),
        },
        1200: {
          slidesPerView: 4.5,
          spaceBetween: 20,
          slidesOffsetBefore: calculateOffset(),
        },
        1400: {
          slidesPerView: 4.5,
          spaceBetween: 20,
          slidesOffsetBefore: calculateOffset(),
        },
        1900: {
          slidesPerView: 4.5,
          spaceBetween: 20,
          slidesOffsetBefore: calculateOffset(),
        },
      },
    });

    // Update `slidesOffsetBefore` on window resize
    window.addEventListener("resize", () => {
      swiper.params.slidesOffsetBefore = calculateOffset();
      swiper.update();
    });
  }
};

export default sectionDestination;
