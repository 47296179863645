/**
 * JQuery
 */

import $ from "jquery";

window.jQuery = $;
window.$ = $;

/**
 * Bootstrap
 */

// import {
// // Alert,       // Dismissible alerts
// // Button,      // Toggle button states
// // Carousel,    // Slideshow component
// // Collapse,    // Accordion-like collapsing elements
// // Dropdown,    // Dropdown menus
// // Modal,       // Popup modals
// // Offcanvas,   // Offcanvas sidebars
// // Popover,     // Pop-up content on hover/click (requires @popperjs/core)
// // ScrollSpy,   // Auto-updating navigation based on scroll position
// // Tab,         // Tabs and pills navigation
// // Toast,       // Small pop-up notifications
// // Tooltip      // Tooltips for elements (requires @popperjs/core)
// } from "bootstrap";

/**
 * Swiper
 */

import Swiper from "swiper/bundle";
import "swiper/css/bundle";
window.Swiper = Swiper;

/**
 * Slick
 */

import "slick-carousel";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

/**
 * FlatPickr
 */

import flatpickr from "flatpickr";
import { Italian } from "flatpickr/dist/l10n/it.js";

window.flatpickr = flatpickr;
window.Italian = Italian;

/**
 * GSAP
 */

// import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";

// gsap.registerPlugin(ScrollTrigger);
// window.gsap = gsap;

/**
 * AOS
 */

// import AOS from "aos";
// import "aos/dist/aos.css";
// AOS.init();

/**
 * Utils
 */

// import geolocator from "./js/utils/geolocator";
// geolocator();

import initMap from "./js/utils/initMap";
window.initMap = initMap;

/**
 * Layout
 */

import navbar from "./js/layout/navbar";
navbar();

import pagination from "./js/layout/pagination";
pagination();

import accessibility from "./js/layout/accessibility";
accessibility();

/**
 * Pages
 */

import frontPage from "./js/pages/frontPage";
frontPage();

import single from "./js/pages/single";
single();

import map from "./js/pages/map";
map();


/**
 * Partials
 */

import sectionDestination from "./js/partials/sectionDestination";
sectionDestination();

import archiveFilters from "./js/partials/archiveFilters";
archiveFilters();

import archiveEvent from "./js/partials/archiveEvent";
archiveEvent();


document.querySelectorAll('.mailpoet_form *').forEach(el => {
    el.classList.forEach(className => {
        if (className.startsWith('mailpoet_form')) {
            el.classList.remove(className);
        }
    });
});
