import { filterResults } from "../partials/archiveFilters";
const map = () => {
  const pageMap = document.querySelector(".page-template-template-mappa");
  if (pageMap) {
    filterResults({
      postType: "attivita",
      tax: {},
      dates: null,
    });
  }
};

export default map;
