import { archivePagination, clearPagination } from "./archivePagination";

const archiveFilters = () => {
  $(() => {
    let currentPage = 1;

    const archiveFilter = document.getElementById("archiveFilter");
    if (archiveFilter) {
      const filterDropdowns = document.querySelectorAll(".archive-filter__dropdown");
      if (filterDropdowns) {
        filterDropdowns.forEach((filterDropdown) => {
          /**
           * Post Filters DRopdown
           */

          const btn = filterDropdown.querySelector(".archive-filter__dropdown__btn");
          if (btn) {
            btn.addEventListener("click", () => {
              filterDropdown.classList.toggle("open");
            });

            window.addEventListener("click", (e) => {
              if (!filterDropdown.contains(e.target)) {
                if (filterDropdown.classList.contains("open")) {
                  filterDropdown.classList.remove("open");
                }
              }
            });
          }
        });
      }

      /**
       * Post Filters
       */

      const filterOptions = archiveFilter.querySelectorAll(".archive-filter__checkbox");
      if (filterOptions.length > 0) {
        filterOptions.forEach((filterOption) => {
          filterOption.addEventListener("change", (e) => {
            filterOption.classList.toggle("checked");
            const selectedFilters = getSelectedFilters();
            filterResults(selectedFilters);
          });
        });

        /**
         * Clear Post Fliters
         */

        const clearFilter = document.getElementById("clearFilter");
        if (clearFilter) {
          clearFilter.addEventListener("change", () => {
            filterOptions.forEach((filterOption) => {
              filterOption.checked = false;
              filterOption.classList.remove("checked");
            });
            const selectedFilters = getSelectedFilters();
            filterResults(selectedFilters);
          });
        }
      }
    }

    /**
     * Archive Events
     */
    const eventCalendar = document.getElementById("event-calendar");
    const eventFilterOptions = document.querySelectorAll(".archive-filter__checkbox-event");
    if (eventCalendar && eventFilterOptions.length > 0) {
      /**
       * Event Calendar
       */

      eventCalendar.addEventListener("change", () => {
        eventFilterOptions.forEach((eventFilterOption) => {
          eventFilterOption.checked = false;
          eventFilterOption.classList.remove("checked");
        });
        const dates = eventCalendar.value.split(" ");
        filterResults({ postType: "evento", dates: [dates[0], dates[dates.length - 1]] });
      });

      /**
       * Evetn Filter Btns
       */

      eventFilterOptions.forEach((eventFilterOption) => {
        eventFilterOption.addEventListener("change", (e) => {
          // Clear Flatpickr selection properly
          if (eventCalendar && eventCalendar._flatpickr) {
            eventCalendar._flatpickr.clear();
          }

          // Remove any manually added classes from days
          const eventCalendarDays = eventCalendar.querySelectorAll(".flatpickr-day");
          eventCalendarDays.forEach((eventCalendarDay) => {
            eventCalendarDay.classList.remove("startRange", "inRange", "endRange");
          });

          // Toggle checked class on selected filter
          eventFilterOption.classList.toggle("checked");
          filterResults({ postType: "evento", dates: [], event_filter: eventFilterOption.value });

          // Unselect other filter options
          eventFilterOptions.forEach((otherEventFilterOption) => {
            if (otherEventFilterOption !== eventFilterOption) {
              otherEventFilterOption.checked = false;
              otherEventFilterOption.classList.remove("checked");
            }
          });
        });
      });
    }
  });
};

export default archiveFilters;

/**
 * Get selected checkboxs
 *
 * @returns object
 */

export const getSelectedFilters = () => {
  const results = {
    postType: null,
    tax: {},
    dates: null,
  };

  const archiveFilter = document.getElementById("archiveFilter");
  if (archiveFilter) {
    results.postType = archiveFilter.getAttribute("data-post-type");
    const checkboxes = archiveFilter.querySelectorAll(".archive-filter__checkbox:checked");
    if (checkboxes.length > 0) {
      checkboxes.forEach((checkbox) => {
        if (!results.tax[checkbox.name]) {
          results.tax[checkbox.name] = [];
        }
        results.tax[checkbox.name].push(checkbox.value);
      });
    }
  }

  return results;
};

/**
 * Function to collect selected filters and update results via AJAX
 */

export const filterResults = (data, page = 1) => {
  showLoader();
  const pageMap = document.querySelector(".page-template-template-mappa");
  // const pageBike = document.querySelector(".term-bike-e-trekking");

  const postType = data.postType;
  let dates = [];
  let event_filter = null;

  if (postType === "evento") {
    dates = data.dates;
    event_filter = data.event_filter;
  }

  // console.log('data in', data);

  $.post({
    url: pubfaAjax.ajax_url,
    data: {
      action: "pubfa_ajax",
      security: pubfaAjax.security,
      page,
      postType,
      taxonomies: data.tax,
      dates,
      event_filter,
    },
    success: (res) => {
      if (!pageMap) {
        resetResults();
      }
      hideLoader();

      if (res.data.posts === "empty string") {
        // console.log('data out', res.data);
        console.log("Ajax call had no results.");
        if (!pageMap) {
          clearPagination();
        }
      } else {
        if (pageMap) {
          initMap(res.data.posts);
        } else {
          listResults(res.data.posts);
          archivePagination(res.data.total_pages, page);
        }
        // console.log('data out', res.data);
        console.log("Ajax call had " + res.data.posts.length + " results.");
      }
    },
    error: (e) => {
      hideLoader();
      console.log("Errore " + e.status + ": " + e.statusText);
    },
  });
};

/**
 * Reset Results
 */

const resetResults = () => {
  const container = document.getElementById("archiveResults");
  if (container) {
    const columns = container.querySelectorAll(".archive-content__item");
    const cards = container.querySelectorAll(".archive-trail__item");
    if (columns.length > 0) {
      columns.forEach((column) => column.remove());
    } else if (cards.length > 0) {
      cards.forEach((card) => card.remove());
    } else {
      console.log("No elements found to remove.");
    }
  } else {
    console.log("Results container not found.");
  }
};

/**
 * List Posts
 *
 * @param {*} data
 */

const listResults = (data) => {
  const container = document.getElementById("archiveResults");

  if (!container) {
    console.error("Error: Results container not found!");
    return;
  }

  if (data.length > 0 && Array.isArray(data)) {
    data.forEach((item) => {
      container.innerHTML += item;
    });
  } else {
    container.innerHTML = "<p>No results found.</p>";
  }
};

/**
 * Show Loader
 */

const showLoader = () => {
  const loader = document.getElementById("loader");
  if (loader) {
    loader.classList.add("show");
  }
};

/**
 * Hide Loader
 */

const hideLoader = () => {
  const loader = document.getElementById("loader");
  if (loader) {
    loader.classList.remove("show");
  }
};
